import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Image, MapPin, Music, Clapperboard, Upload, BarChart, AlertCircle, Video } from 'lucide-react';
import { useAuth } from './AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { db, storage, analyticsEvents } from '../lib/firebase';
import { collection, addDoc, serverTimestamp, updateDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { LocationPicker } from './LocationPicker';
import { SpotifySearch } from './SpotifySearch';
import { YoutubeSearch } from './YoutubeSearch';
import { CreatePoll } from './CreatePoll';
import { getPostStorageRef } from '../utils/posts/storage';
import { moderateImage } from '../utils/vision';
import { ImageEditor } from './ImageEditor';
import { containsBlockedWords } from '../utils/content/filter';
import { YouTubeUploader } from './YouTubeUploader';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    placeholder: 'O que está pensando? 💭',
    waitMessage: 'Aguarde {time}',
    buttons: {
      camera: 'Câmera',
      gallery: 'Galeria',
      location: 'Localização',
      music: 'Música', 
      video: 'Vídeo',
      youtubeVideo: 'Enviar vídeo',
      poll: 'Enquete',
      post: 'Postar',
      posting: 'Postando...',
      saveWarning: 'Salvar Alerta',
      editWarning: 'Editar Alerta',
      cancel: 'Cancelar'
    },
    errors: {
      linksNotAllowed: 'Links não são permitidos em posts',
      postTooLong: 'Post deve ter no máximo {max} caracteres',
      uploadFailed: 'Falha ao enviar post',
      imageProcessing: 'Falha ao processar imagem',
      inappropriateContent: 'Esta imagem contém conteúdo promocional ou material inadequado. Por favor, escolha outra imagem.',
      blockedWord: 'Sua mensagem contém uma palavra bloqueada: {word}'
    },
    success: {
      postCreated: 'Post criado com sucesso!',
      warningUpdated: 'Mensagem de alerta atualizada com sucesso'
    },
    noWarning: 'Nenhum alerta definido',
    location: {
      visible: 'Localização visível',
      remove: 'Remover localização'
    }
  },
  'en': {
    placeholder: 'What\'s on your mind? 💭',
    waitMessage: 'Wait {time} before posting again',
    buttons: {
      camera: 'Camera',
      gallery: 'Gallery',
      location: 'Location',
      music: 'Music',
      video: 'Video',
      youtubeVideo: 'Upload video',
      poll: 'Poll',
      post: 'Post',
      posting: 'Posting...',
      saveWarning: 'Save Warning',
      editWarning: 'Edit Warning',
      cancel: 'Cancel'
    },
    errors: {
      linksNotAllowed: 'Links are not allowed in posts',
      postTooLong: 'Post must be {max} characters or less',
      uploadFailed: 'Failed to upload post',
      imageProcessing: 'Failed to process image',
      inappropriateContent: 'This image contains promotional content or inappropriate material. Please choose another image.',
      blockedWord: 'Your message contains a blocked word: {word}'
    },
    success: {
      postCreated: 'Post created successfully!',
      warningUpdated: 'Warning message updated successfully'
    },
    noWarning: 'No warning set',
    location: {
      visible: 'Location visible',
      remove: 'Remove location'
    }
  }
};

const MAX_POST_LENGTH = 120;
const POST_COOLDOWN = 60 * 60 * 1000; // 60 minutes in milliseconds

interface Poll {
  question: string;
  options: {
    text: string;
    votes: string[];
  }[];
  totalVotes: number;
  endTime: Date;
}

export function CreatePost() {
  const [content, setContent] = useState('');
  const [media, setMedia] = useState<{ file: File; type: 'image' | 'video' } | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [lastPostTime, setLastPostTime] = useState<Date | null>(null);
  const [cooldownRemaining, setCooldownRemaining] = useState(0);
  const [showLocationPicker, setShowLocationPicker] = useState(false);
  const [location, setLocation] = useState<{ latitude: number; longitude: number } | null>(null);
  const [showSpotifySearch, setShowSpotifySearch] = useState(false);
  const [showYoutubeSearch, setShowYoutubeSearch] = useState(false);
  const [showYoutubeUploader, setShowYoutubeUploader] = useState(false);
  const [showPollCreator, setShowPollCreator] = useState(false);
  const [poll, setPoll] = useState<Poll | null>(null);
  const [selectedTrack, setSelectedTrack] = useState<{
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  } | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<{
    id: string;
    title: string;
    channelTitle: string;
    thumbnailUrl: string;
  } | null>(null);
  const [adminWarning, setAdminWarning] = useState('');
  const [isEditingWarning, setIsEditingWarning] = useState(false);
  const [currentWarning, setCurrentWarning] = useState('');
  const [moderating, setModerating] = useState(false);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [language, setLanguage] = useState('en');

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const isAdmin = user?.email?.toLowerCase() === 'afonsoalcantara@outlook.com';

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string, params?: Record<string, string>) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }

    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  };

  useEffect(() => {
    const lastPost = localStorage.getItem('lastPostTime');
    if (lastPost) {
      setLastPostTime(new Date(lastPost));
    }

    // Fetch current warning
    const fetchWarning = async () => {
      try {
        const warningDoc = await getDoc(doc(db, 'settings', 'adminWarning'));
        if (warningDoc.exists()) {
          setCurrentWarning(warningDoc.data().message || '');
          setAdminWarning(warningDoc.data().message || '');
        }
      } catch (error) {
        console.error('Error fetching warning:', error);
      }
    };
    fetchWarning();
  }, []);

  useEffect(() => {
    if (!lastPostTime) return;

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const lastPost = lastPostTime.getTime();
      const timeLeft = Math.max(0, POST_COOLDOWN - (now - lastPost));
      setCooldownRemaining(timeLeft);

      if (timeLeft === 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [lastPostTime]);

  const formatCooldownTime = (ms: number): string => {
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const resetFileInputs = () => {
    if (fileInputRef.current) fileInputRef.current.value = '';
    setMedia(null);
    setPreviewUrl(null);
    setSelectedImage(null);
    setShowImageEditor(false);
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      resetFileInputs();
      return;
    }

    if (cooldownRemaining > 0) {
      toast.error(t('waitMessage', { time: formatCooldownTime(cooldownRemaining) }));
      resetFileInputs();
      return;
    }

    // Explicitly reject GIF files
    if (file.type === 'image/gif') {
      toast.error('GIF files are not allowed. Please upload a JPEG, PNG, or WebP image.');
      resetFileInputs();
      return;
    }

    try {
      // Show image editor
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        setShowImageEditor(true);
      };
      reader.readAsDataURL(file);
    } catch (error: any) {
      console.error('Error processing image:', error);
      toast.error(error.message || t('errors.imageProcessing'));
      resetFileInputs();
    }
  };

  const handleCroppedImage = async (blob: Blob) => {
    try {
      setModerating(true);
      
      // Convert blob to base64 for Cloud Vision
      const base64Image = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      });

      // Check image with Cloud Vision
      const isImageSafe = await moderateImage(base64Image);
      if (!isImageSafe) {
        toast.error(t('errors.inappropriateContent'));
        resetFileInputs();
        return;
      }

      const file = new File([blob], `post-${Date.now()}.jpg`, { type: 'image/jpeg' });
      setMedia({ file, type: 'image' });
      setPreviewUrl(URL.createObjectURL(file));
      setContent(' ');
      setShowImageEditor(false);
    } catch (error: any) {
      console.error('Error processing image:', error);
      toast.error(error.message || t('errors.imageProcessing'));
      resetFileInputs();
    } finally {
      setModerating(false);
    }
  };

  const handleYouTubeVideoUploaded = (videoData: { id: string; title: string; thumbnailUrl: string }) => {
    setSelectedVideo({
      id: videoData.id,
      title: videoData.title,
      channelTitle: 'Your YouTube Channel',
      thumbnailUrl: videoData.thumbnailUrl
    });
    setContent(' ');
    setShowYoutubeUploader(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if ((!content.trim() && !media && !location && !selectedTrack && !selectedVideo && !poll) || !user || isSubmitting) return;

    if (cooldownRemaining > 0) {
      toast.error(t('waitMessage', { time: formatCooldownTime(cooldownRemaining) }));
      return;
    }

    if (content.length > MAX_POST_LENGTH) {
      toast.error(t('errors.postTooLong', { max: MAX_POST_LENGTH.toString() }));
      return;
    }

    // Check for blocked words
    const { isBlocked, blockedWord } = containsBlockedWords(content);
    if (isBlocked) {
      toast.error(t('errors.blockedWord', { word: blockedWord || '' }));
      return;
    }

    // Check for links in content
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    if (urlRegex.test(content)) {
      toast.error(t('errors.linksNotAllowed'));
      return;
    }

    try {
      setIsSubmitting(true);
      let mediaUrl = '';
      let mediaType = null;
      let postDoc;
      
      // Create post document first
      postDoc = await addDoc(collection(db, 'posts'), {
        content: content.trim(),
        authorId: user.uid,
        authorName: user.displayName || '',
        authorUsername: user.displayName?.replace('@', ''),
        authorPhotoURL: user.photoURL,
        timestamp: serverTimestamp(),
        views: 0,
        comments: [],
        likes: [],
        stars: [],
        trash: [],
        forwards: []
      });

      if (media) {
        // Upload media file
        const storageRef = getPostStorageRef(user.uid, postDoc.id);
        await uploadBytes(storageRef, media.file);
        mediaUrl = await getDownloadURL(storageRef);
        mediaType = media.type;
      }

      // Update post with media and other content
      await updateDoc(doc(db, 'posts', postDoc.id), {
        imageUrl: mediaType === 'image' ? mediaUrl : null,
        videoUrl: mediaType === 'video' ? mediaUrl : null,
        audioUrl: mediaType === 'audio' ? mediaUrl : null,
        location: location,
        spotifyTrack: selectedTrack,
        youtubeVideo: selectedVideo,
        poll: poll ? {
          ...poll,
          options: poll.options.map(option => ({
            ...option,
            votes: []
          }))
        } : null
      });

      analyticsEvents.createPost();
      setContent('');
      setMedia(null);
      setPreviewUrl(null);
      setLocation(null);
      setSelectedTrack(null);
      setSelectedVideo(null);
      setPoll(null);
      setShowLocationPicker(false);
      
      // Update last post time
      const now = new Date();
      setLastPostTime(now);
      localStorage.setItem('lastPostTime', now.toISOString());
      
      toast.success(t('success.postCreated'));
    } catch (error) {
      console.error('Error creating post:', error);
      toast.error(t('errors.uploadFailed'));
    } finally {
      setIsSubmitting(false);
      resetFileInputs();
    }
  };

  const handleUpdateWarning = async () => {
    if (!isAdmin) return;

    try {
      await setDoc(doc(db, 'settings', 'adminWarning'), {
        message: adminWarning,
        updatedAt: serverTimestamp(),
        updatedBy: user?.email
      });
      setIsEditingWarning(false);
      setCurrentWarning(adminWarning);
      toast.success(t('success.warningUpdated'));
    } catch (error) {
      console.error('Error updating warning:', error);
      toast.error('Failed to update warning message');
    }
  };

  return (
    <div className={isDarkMode ? 'bg-gray-800' : 'bg-white'}>
      <form onSubmit={handleSubmit} className={`border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} p-4`}>
        <div className="flex flex-col items-center space-y-4">
          <Link to={`/profile/${user?.displayName?.replace('@', '')}`}>
            <img
              src={user?.photoURL || ''}
              alt={user?.displayName || 'User'}
              className="h-16 w-16 rounded-full"
            />
          </Link>
          <div className="w-full max-w-xl">
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder={cooldownRemaining > 0 
                ? t('waitMessage', { time: formatCooldownTime(cooldownRemaining) })
                : t('placeholder')}
              className={`w-full resize-none border-0 focus:ring-0 text-base placeholder-gray-500 bg-transparent transition-all duration-300 text-center ${
                content.length > MAX_POST_LENGTH 
                  ? 'text-red-500' 
                  : isDarkMode 
                    ? 'text-white placeholder-gray-400' 
                    : 'text-gray-900'
              }`}
              rows={1}
              maxLength={MAX_POST_LENGTH}
              disabled={cooldownRemaining > 0}
            />

            {previewUrl && (
              <div className="relative mt-2">
                <img
                  src={previewUrl}
                  alt="Preview"
                  className="rounded-2xl max-h-80 object-cover"
                />
                <button
                  type="button"
                  onClick={() => {
                    setMedia(null);
                    setPreviewUrl(null);
                    if (!content.trim()) {
                      setContent('');
                    }
                    resetFileInputs();
                  }}
                  className="absolute top-2 right-2 p-1 bg-black/50 rounded-full text-white hover:bg-black/70"
                >
                  <Image className="h-5 w-5" />
                </button>
              </div>
            )}

            {selectedTrack && (
              <div className="mt-4 relative aspect-square">
                <img
                  src={selectedTrack.albumImage}
                  alt={selectedTrack.name}
                  className="w-full h-full object-cover rounded-lg"
                />
                <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center p-4 text-white">
                  <h3 className="text-3xl font-bold text-center mb-4">{selectedTrack.name}</h3>
                  <p className="text-xl text-center">{selectedTrack.artists.join(', ')}</p>
                  <button
                    type="button"
                    onClick={() => setSelectedTrack(null)}
                    className="absolute top-2 right-2 p-2 bg-black/50 rounded-full hover:bg-black/70"
                  >
                    <Image className="h-5 w-5" />
                  </button>
                </div>
              </div>
            )}

            {selectedVideo && (
              <div className="mt-4 relative aspect-video">
                <img
                  src={selectedVideo.thumbnailUrl}
                  alt={selectedVideo.title}
                  className="w-full h-full object-cover rounded-lg"
                />
                <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center p-4 text-white">
                  <h3 className="text-xl font-bold text-center mb-2">{selectedVideo.title}</h3>
                  <p className="text-sm text-center">{selectedVideo.channelTitle}</p>
                  <button
                    type="button"
                    onClick={() => setSelectedVideo(null)}
                    className="absolute top-2 right-2 p-2 bg-black/50 rounded-full hover:bg-black/70"
                  >
                    <Image className="h-5 w-5" />
                  </button>
                </div>
              </div>
            )}

            {poll && (
              <div className={`mt-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg p-4`}>
                <div className="flex items-center justify-between mb-4">
                  <h3 className={`font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {poll.question}
                  </h3>
                  <button
                    onClick={() => setPoll(null)}
                    className={isDarkMode ? 'text-red-400 hover:text-red-300' : 'text-red-500 hover:text-red-600'}
                  >
                    <Image className="h-5 w-5" />
                  </button>
                </div>
                <div className="space-y-2">
                  {poll.options.map((option, index) => (
                    <div 
                      key={index} 
                      className={`${
                        isDarkMode 
                          ? 'bg-gray-800 border-gray-600' 
                          : 'bg-white border-gray-200'
                      } p-3 rounded border`}
                    >
                      <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>
                        {option.text}
                      </span>
                    </div>
                  ))}
                </div>
                <p className={`text-sm mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {t('poll.endsIn', { 
                    hours: Math.ceil((poll.endTime.getTime() - Date.now()) / (1000 * 60 * 60)).toString() 
                  })}
                </p>
              </div>
            )}

            {location && (
              <div className={`mt-4 p-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg`}>
                <div className="flex items-center justify-between">
                  <span className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                    📍 {t('location.visible')}
                  </span>
                  <button
                    type="button"
                    onClick={() => setLocation(null)}
                    className={isDarkMode ? 'text-red-400' : 'text-red-500'}
                  >
                    {t('location.remove')}
                  </button>
                </div>
              </div>
            )}

            <div className="mt-4 flex flex-col space-y-4">
              <div className="flex justify-center space-x-2">
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                  className={`p-2 ${
                    isDarkMode 
                      ? 'text-gray-300 hover:bg-gray-700' 
                      : 'text-black hover:bg-blue-50'
                  } rounded-full`}
                  title={t('buttons.gallery')}
                  disabled={cooldownRemaining > 0 || moderating}
                >
                  <Image className="h-5 w-5" />
                </button>
                <button
                  type="button"
                  onClick={() => setShowLocationPicker(true)}
                  className={`p-2 ${
                    isDarkMode 
                      ? 'text-gray-300 hover:bg-gray-700' 
                      : 'text-black hover:bg-blue-50'
                  } rounded-full`}
                  title={t('buttons.location')}
                  disabled={cooldownRemaining > 0}
                >
                  <MapPin className="h-5 w-5" />
                </button>
                <button
                  type="button"
                  onClick={() => setShowSpotifySearch(true)}
                  className={`p-2 ${
                    isDarkMode 
                      ? 'text-green-400 hover:bg-gray-700' 
                      : 'text-green-500 hover:bg-blue-50'
                  } rounded-full`}
                  title={t('buttons.music')}
                  disabled={cooldownRemaining > 0}
                >
                  <Music className="h-5 w-5" />
                </button>
                <button
                  type="button"
                  onClick={() => setShowYoutubeSearch(true)}
                  className={`p-2 ${
                    isDarkMode 
                      ? 'text-red-400 hover:bg-gray-700' 
                      : 'text-red-500 hover:bg-blue-50'
                  } rounded-full`}
                  title={t('buttons.video')}
                  disabled={cooldownRemaining > 0}
                >
                  <Clapperboard className="h-5 w-5" />
                </button>
                <button
                  type="button"
                  onClick={() => setShowYoutubeUploader(true)}
                  className={`p-2 ${
                    isDarkMode 
                      ? 'text-red-400 hover:bg-gray-700' 
                      : 'text-red-500 hover:bg-blue-50'
                  } rounded-full`}
                  title={t('buttons.youtubeVideo')}
                  disabled={cooldownRemaining > 0}
                >
                  <Video className="h-5 w-5" />
                </button>
                <button
                  type="button"
                  onClick={() => setShowPollCreator(true)}
                  className={`p-2 ${
                    isDarkMode 
                      ? 'text-purple-400 hover:bg-gray-700' 
                      : 'text-purple-500 hover:bg-blue-50'
                  } rounded-full`}
                  title={t('buttons.poll')}
                  disabled={cooldownRemaining > 0}
                >
                  <BarChart className="h-5 w-5" />
                </button>
              </div>

              <div className="flex justify-center items-center space-x-4">
                <span className={`text-sm ${
                  content.length > MAX_POST_LENGTH 
                    ? 'text-red-500' 
                    : isDarkMode 
                      ? 'text-gray-400' 
                      : 'text-gray-500'
                }`}>
                  {content.length}/{MAX_POST_LENGTH}
                </span>
                <button
                  type="submit"
                  disabled={(!content.trim() && !media && !location && !selectedTrack && !selectedVideo && !poll) || isSubmitting || content.length > MAX_POST_LENGTH || cooldownRemaining > 0}
                  className={`px-4 py-2 text-white rounded-full font-bold disabled:opacity-50 disabled:cursor-not-allowed ${
                    isDarkMode 
                      ? 'bg-blue-600 hover:bg-blue-700' 
                      : 'bg-black hover:bg-gray-800'
                  }`}
                >
                  {isSubmitting ? t('buttons.posting') : cooldownRemaining > 0 ? t('waitMessage', { time: formatCooldownTime(cooldownRemaining) }) : t('buttons.post')}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Hidden file inputs */}
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleFileSelect}
          className="hidden"
          onClick={(e) => {
            (e.target as HTMLInputElement).value = '';
          }}
        />
      </form>

      {/* Admin Warning Block */}
      {(isAdmin || currentWarning) && (
        <div className={`p-4 ${isDarkMode ? 'bg-red-900/20' : 'bg-red-50'} border-t ${isDarkMode ? 'border-red-800/30' : 'border-red-100'}`}>
          <div className="flex items-start space-x-2">
            <AlertCircle className={`h-5 w-5 mt-0.5 ${isDarkMode ? 'text-red-400' : 'text-red-500'}`} />
            <div className="flex-1">
              {isEditingWarning && isAdmin ? (
                <div className="space-y-2">
                  <textarea
                    value={adminWarning}
                    onChange={(e) => setAdminWarning(e.target.value)}
                    placeholder="Enter warning message..."
                    className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 ${
                      isDarkMode 
                        ? 'bg-gray-800 border-gray-700 text-white placeholder-gray-400' 
                        : 'bg-white border-red-200 text-gray-900 placeholder-gray-500'
                    }`}
                    rows={3}
                  />
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      onClick={() => {
                        setIsEditingWarning(false);
                        setAdminWarning(currentWarning);
                      }}
                      className={`px-3 py-1 rounded-md ${
                        isDarkMode 
                          ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' 
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      }`}
                    >
                      {t('buttons.cancel')}
                    </button>
                    <button
                      type="button"
                      onClick={handleUpdateWarning}
                      className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
                    >
                      {t('buttons.saveWarning')}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <p className={`text-sm ${isDarkMode ? 'text-red-300' : 'text-red-600'}`}>
                    {currentWarning || t('noWarning')}
                  </p>
                  {isAdmin && (
                    <button
                      onClick={() => {
                        setIsEditingWarning(true);
                        setAdminWarning(currentWarning);
                      }}
                      className={`mt-2 text-sm ${
                        isDarkMode 
                          ? 'text-red-400 hover:text-red-300' 
                          : 'text-red-600 hover:text-red-700'
                      }`}
                    >
                      {t('buttons.editWarning')}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {showLocationPicker && (
        <LocationPicker
          onLocationSelect={(coords) => {
            setLocation(coords);
            setShowLocationPicker(false);
          }}
          onClose={() => setShowLocationPicker(false)}
          isDarkMode={isDarkMode}
        />
      )}

      {showSpotifySearch && (
        <SpotifySearch
          onSelect={(track) => {
            setSelectedTrack(track);
            setContent(' ');
            setShowSpotifySearch(false);
          }}
          onClose={() => setShowSpotifySearch(false)}
          isDarkMode={isDarkMode}
        />
      )}

      {showYoutubeSearch && (
        <YoutubeSearch
          onSelect={(video) => {
            setSelectedVideo(video);
            setContent(' ');
            setShowYoutubeSearch(false);
          }}
          onClose={() => setShowYoutubeSearch(false)}
          isDarkMode={isDarkMode}
        />
      )}

      {showYoutubeUploader && (
        <YouTubeUploader
          onVideoUploaded={handleYouTubeVideoUploaded}
          onCancel={() => setShowYoutubeUploader(false)}
          isDarkMode={isDarkMode}
        />
      )}

      {showPollCreator && (
        <CreatePoll
          onPollCreated={(newPoll) => {
            setPoll(newPoll);
            setContent(' ');
            setShowPollCreator(false);
          }}
          onClose={() => setShowPollCreator(false)}
          isDarkMode={isDarkMode}
        />
      )}

      {showImageEditor && selectedImage && (
        <ImageEditor
          image={selectedImage}
          onSave={handleCroppedImage}
          onCancel={() => {
            setShowImageEditor(false);
            setSelectedImage(null);
            resetFileInputs();
          }}
          aspectRatio={1}
          isProcessing={moderating}
        />
      )}
    </div>
  );
}
import { useState, useRef, useEffect } from 'react';
import { Upload, Loader2, X, Video, Globe } from 'lucide-react';
import { uploadVideoToYouTube } from '../utils/youtube/upload';
import toast from 'react-hot-toast';

interface YouTubeUploaderProps {
  onVideoUploaded: (videoData: { id: string; title: string; thumbnailUrl: string }) => void;
  onCancel: () => void;
  isDarkMode?: boolean;
}

const translations = {
  'pt-BR': {
    title: 'Enviar vídeo',
    fileSelect: 'Clique para selecionar um vídeo',
    fileSize: 'MP4, MOV ou WebM até 500MB',
    titleLabel: 'Título',
    titlePlaceholder: 'Digite o título do vídeo',
    descriptionLabel: 'Descrição (opcional)',
    descriptionPlaceholder: 'Digite a descrição do vídeo',
    cancel: 'Cancelar',
    upload: 'Enviar',
    uploading: 'Enviando vídeo...',
    processing: 'Processando vídeo...',
    waitMessage: 'Por favor, não feche esta janela',
    success: 'Vídeo enviado com sucesso!',
    errors: {
      noFile: 'Por favor, selecione um arquivo de vídeo e forneça um título',
      fileTooBig: 'O arquivo de vídeo é muito grande. O tamanho máximo é 500MB.',
      invalidType: 'Por favor, selecione um arquivo de vídeo válido.',
      uploadFailed: 'Falha ao enviar vídeo'
    }
  },
  'en': {
    title: 'Upload video',
    fileSelect: 'Click to select a video',
    fileSize: 'MP4, MOV, or WebM up to 500MB',
    titleLabel: 'Title',
    titlePlaceholder: 'Enter video title',
    descriptionLabel: 'Description (optional)',
    descriptionPlaceholder: 'Enter video description',
    cancel: 'Cancel',
    upload: 'Upload',
    uploading: 'Uploading video...',
    processing: 'Processing video...',
    waitMessage: 'Please don\'t close this window',
    success: 'Video uploaded successfully!',
    errors: {
      noFile: 'Please select a video file and provide a title',
      fileTooBig: 'Video file is too large. Maximum size is 500MB.',
      invalidType: 'Please select a valid video file.',
      uploadFailed: 'Failed to upload video'
    }
  }
};

export function YouTubeUploader({ onVideoUploaded, onCancel, isDarkMode = false }: YouTubeUploaderProps) {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    // Detect user's language
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Validate file size (max 500MB for YouTube)
      if (file.size > 500 * 1024 * 1024) {
        toast.error(t('errors.fileTooBig'));
        return;
      }

      // Validate file type
      if (!file.type.startsWith('video/')) {
        toast.error(t('errors.invalidType'));
        return;
      }

      setSelectedFile(file);
      
      // Auto-generate title from filename
      if (!title) {
        const fileName = file.name.replace(/\.[^/.]+$/, ''); // Remove extension
        setTitle(fileName.replace(/[-_]/g, ' ')); // Replace dashes and underscores with spaces
      }
    }
  };

  const handleUpload = async () => {
    if (!selectedFile || !title.trim()) {
      toast.error(t('errors.noFile'));
      return;
    }

    try {
      setUploading(true);
      
      const result = await uploadVideoToYouTube(
        selectedFile,
        title,
        description,
        'user123', // This would be the actual user ID in a real implementation
        (progressData) => {
          setProgress(progressData.progress);
        }
      );

      toast.success(t('success'));
      onVideoUploaded({
        id: result.videoId,
        title: title,
        thumbnailUrl: result.thumbnailUrl
      });
    } catch (error: any) {
      console.error('Error uploading video:', error);
      toast.error(error.message || t('errors.uploadFailed'));
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className={`w-full max-w-md rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-6`}>
        <div className="flex justify-between items-center mb-6">
          <h2 className={`text-xl font-semibold flex items-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            <Video className="h-5 w-5 mr-2 text-red-500" />
            {t('title')}
          </h2>
          <button
            onClick={onCancel}
            className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
          >
            <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        </div>

        {uploading ? (
          <div className="text-center py-8">
            <Loader2 className={`h-12 w-12 animate-spin mx-auto mb-4 ${isDarkMode ? 'text-red-400' : 'text-red-500'}`} />
            <p className={`mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {progress < 50 ? t('uploading') : t('processing')}
            </p>
            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
              <div 
                className="bg-red-500 h-2.5 rounded-full transition-all duration-300" 
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {t('waitMessage')}
            </p>
          </div>
        ) : (
          <div className="space-y-4">
            {selectedFile ? (
              <div className={`p-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} rounded-lg`}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Video className={`h-5 w-5 mr-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
                    <span className={`truncate ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {selectedFile.name}
                    </span>
                  </div>
                  <button
                    onClick={() => setSelectedFile(null)}
                    className={`p-1 ${isDarkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-200'} rounded-full`}
                  >
                    <X className={`h-4 w-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
                  </button>
                </div>
                <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {(selectedFile.size / (1024 * 1024)).toFixed(2)} MB
                </p>
              </div>
            ) : (
              <div 
                onClick={() => fileInputRef.current?.click()}
                className={`border-2 border-dashed ${
                  isDarkMode 
                    ? 'border-gray-600 hover:border-gray-500' 
                    : 'border-gray-300 hover:border-gray-400'
                } rounded-lg p-8 text-center cursor-pointer transition-colors`}
              >
                <Upload className={`h-10 w-10 mx-auto mb-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                <p className={`text-sm font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {t('fileSelect')}
                </p>
                <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {t('fileSize')}
                </p>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="video/*"
                  onChange={handleFileSelect}
                  className="hidden"
                />
              </div>
            )}

            <div>
              <label className={`block text-sm font-medium mb-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('titleLabel')}
              </label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder={t('titlePlaceholder')}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 ${
                  isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                    : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                }`}
                required
                maxLength={100}
              />
            </div>

            <div>
              <label className={`block text-sm font-medium mb-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('descriptionLabel')}
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={t('descriptionPlaceholder')}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 resize-none ${
                  isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                    : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                }`}
                rows={3}
                maxLength={5000}
              />
            </div>

            <div className="flex justify-end space-x-3 pt-4">
              <button
                onClick={onCancel}
                className={`px-4 py-2 ${
                  isDarkMode 
                    ? 'text-gray-300 hover:bg-gray-700' 
                    : 'text-gray-700 hover:bg-gray-100'
                } rounded-lg`}
              >
                {t('cancel')}
              </button>
              <button
                onClick={handleUpload}
                disabled={!selectedFile || !title.trim()}
                className={`px-4 py-2 text-white rounded-lg disabled:opacity-50 ${
                  isDarkMode 
                    ? 'bg-red-600 hover:bg-red-700' 
                    : 'bg-red-500 hover:bg-red-600'
                }`}
              >
                {t('upload')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
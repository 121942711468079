import YouTube from 'react-youtube';

interface YouTubeEmbedProps {
  video: {
    id: string;
    title: string;
    channelTitle: string;
    thumbnailUrl: string;
  };
  isDarkMode?: boolean;
}

export function YouTubeEmbed({ video, isDarkMode = false }: YouTubeEmbedProps) {
  return (
    <div className="mb-3">
      <div className="relative w-full aspect-video rounded-lg overflow-hidden">
        <YouTube
          videoId={video.id}
          opts={{
            width: '100%',
            height: '100%',
            playerVars: {
              modestbranding: 1,
              rel: 0,
              playsinline: 1,
              origin: window.location.origin,
              host: 'https://www.youtube.com',
              enablejsapi: 1
            }
          }}
          className="absolute inset-0"
          onError={(e) => console.error('YouTube player error:', e)}
        />
      </div>
      <div className="mt-2">
        <h3 className={`font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          {video.title}
        </h3>
        <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
          {video.channelTitle}
        </p>
      </div>
    </div>
  );
}